<template>
  <v-container fluid>
    <h3 class="page-title">商品管理</h3>
    <v-row>
      <v-col cols="4" md="auto">
        <v-select
          label="商品類別"
          hide-details
          v-model="type"
          :items="[
            { text: '全部', value: '' },
            { text: '機台商品', value: '機台商品' },
            { text: '兌換商品', value: '兌換商品' },
            { text: '換抽商品', value: '換抽商品' },
          ]"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="4" md="auto">
        <v-text-field
          v-model="name"
          label="商品名稱"
          outlined
          dense
          clearable
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="auto">
        <v-select
          label="分類"
          hide-details
          v-model="category"
          :items="categoryItems"
          outlined
          dense
          multiple
          chips
          small-chips
          attach
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <div class="range-inputs">
          <label class="range-inputs__label">點數</label>
          <v-text-field
            v-model.number="pointsRange[0]"
            outlined
            dense
            hide-details
            clearable
            class="range-inputs__field"
          >
          </v-text-field>
          <div class="range-inputs__dash"></div>
          <v-text-field
            v-model.number="pointsRange[1]"
            outlined
            dense
            hide-details
            clearable
            class="range-inputs__field"
            @click:clear="pointsRange[1] = maxProductPoints"
          >
          </v-text-field>
        </div>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="primary" dense hide-details @click="addRecord"
          >新增</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [30, 100, -1],
          }"
        >
          <template v-slot:item.imageUrl="{ item }">
            <span
              @click="
                uploadImageItem = item;
                $refs.file.click();
              "
            >
              <template v-if="item.imageUrl">
                <v-img
                  class="pointer mx-auto"
                  :lazy-src="item.imageUrl"
                  :src="item.imageUrl"
                  max-height="60"
                  max-width="60"
                ></v-img>
              </template>
              <template v-else> <span class="pointer">---</span></template>
            </span>
          </template>
          <template v-slot:item.isAvailable="{ item }">
            <v-checkbox
              class="empty-label"
              v-model="item.isAvailable"
              @change="update(item, 'isAvailable')"
            ></v-checkbox>
          </template>
          <template v-slot:item.isOnline="{ item }">
            <v-checkbox
              class="empty-label"
              v-model="item.isOnline"
              @change="update(item, 'isOnline')"
            ></v-checkbox>
          </template>
          <template v-slot:item.name="{ item }">
            <v-edit-dialog
              :return-value.sync="item.name"
              @save="update(item, 'name')"
            >
              {{ item.name || "---" }}
              <template v-slot:input>
                <v-text-field
                  v-model="item.name"
                  label="商品名稱"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.category="{ item }">
            <v-edit-dialog @save="update(item, 'category')">
              {{ item.category || "---" }}
              <template v-slot:input>
                <v-combobox
                  :items="[{ text: '---', value: null }, ...categoryItems]"
                  :value="item.category"
                  label="機台商品"
                  outlined
                  dense
                  hide-details
                  class="my-4"
                  @change="updateCategory(item, $event)"
                  @input="updateCategory(item, $event)"
                ></v-combobox>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.points="{ item }">
            <v-edit-dialog
              :return-value.sync="item.points"
              @save="update(item, 'points')"
            >
              {{ item.points || "---" }}
              <template v-slot:input>
                <v-text-field
                  v-model.number="item.points"
                  label="點數"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.price="{ item }">
            <v-edit-dialog
              :return-value.sync="item.price"
              @save="update(item, 'price')"
            >
              {{ item.price || "---" }}
              <template v-slot:input>
                <v-text-field
                  v-model.number="item.price"
                  label="售價"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.retailPrice="{ item }">
            <v-edit-dialog
              :return-value.sync="item.retailPrice"
              @save="update(item, 'retailPrice')"
            >
              {{ item.retailPrice || "---" }}
              <template v-slot:input>
                <v-text-field
                  v-model.number="item.retailPrice"
                  label="零售價"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.cost="{ item }">
            <v-edit-dialog
              :return-value.sync="item.cost"
              @save="update(item, 'cost')"
            >
              {{ item.cost || "---" }}
              <template v-slot:input>
                <v-text-field
                  v-model.number="item.cost"
                  label="成本"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.type="{ item }">
            <v-select
              class="typeWidth"
              hide-details
              v-model="item.type"
              :items="[
                { text: '-', value: '' },
                { text: '機台商品', value: '機台商品' },
                { text: '兌換商品', value: '兌換商品' },
                { text: '換抽商品', value: '換抽商品' },
              ]"
              @change="update(item, 'type')"
              outlined
              dense
            ></v-select>
          </template>
          <template v-slot:item.remove="{ item }">
            <v-icon
              color="error darken-1"
              class="pointer"
              @click="remove(item)"
            >
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <input
      ref="file"
      type="file"
      style="display: none"
      @change="onFileChange"
    />
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "Product",
  data: () => ({
    uploadImageItem: null,
    data: [],
    category: [],
    type: "",
    pointsRange: [0, 0],
    isOnline: null,
    isAvailable: null,
    name: "",
  }),
  computed: {
    categoryItems() {
      let categorys = this.data
        .map((item) => item.category)
        .filter((item) => item);
      categorys = _.uniq(categorys);
      categorys = categorys.map((item) => ({ text: item, value: item }));
      categorys.reverse();
      return categorys;
    },
    filterData() {
      let data = this.data;
      if (this.type) {
        data = data.filter((item) => item.type === this.type);
      }
      if (this.name) {
        data = data.filter((item) => item.name?.includes(this.name));
      }
      if (this.category.length) {
        data = data.filter((item) => this.category?.includes(item.category));
      }
      if (this.pointsRange[0]) {
        data = data.filter((o) => o.points >= this.pointsRange[0]);
      }
      if (this.pointsRange[1]) {
        data = data.filter((o) => o.points <= this.pointsRange[1]);
      }
      return data;
    },
    headers() {
      const header = [
        {
          text: "上架",
          value: "isAvailable",
          align: "center",
          sortable: true,
          width: 80,
        },
        {
          text: "商品類別",
          value: "type",
          align: "left",
          sortable: false,
          width: 160,
        },
        { text: "商品名稱", value: "name", align: "left", sortable: false },
        { text: "分類", value: "category", align: "left", sortable: true },
        { text: "點數", value: "points", align: "left", sortable: true },
        { text: "成本", value: "cost", align: "left", sortable: true },
        { text: "售價", value: "price", align: "left", sortable: true },
        { text: "零售價", value: "retailPrice", align: "left", sortable: true },
        {
          text: "線上兌換",
          value: "isOnline",
          align: "center",
          sortable: true,
        },
        { text: "圖片", value: "imageUrl", align: "center", sortable: true },
        { text: "刪除", value: "remove", align: "center", sortable: false },
      ];
      return header.filter((item) => {
        return this.allowViewCost || item.value !== "cost";
      });
    },
    allowViewCost() {
      return ["管理者", "主管", "採購"].includes(this.$store.state.user.角色);
    },
    maxProductPoints() {
      const max = _.max(this.data.map((product) => product.points));
      return max || 0;
    },
  },
  created() {
    this.$nextTick(() => {
      this.ready = true;
      this.load();
    });
  },
  methods: {
    async onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let file = files[0];
      let formdata = new FormData();
      formdata.append("file", files[0], this.uploadImageItem._id);
      let { data } = await this.axios.post(`/productImage`, formdata);
      this.uploadImageItem.imageUrl = data.imageUrl;
      await this.update(this.uploadImageItem, "imageUrl");
      this.$forceUpdate();
    },
    async addRecord() {
      await this.axios.post(`/product`, {
        name: null,
        type: null,
        isOnline: false,
        isAvailable: false,
      });
      this.load();
    },
    async load() {
      if (!this.ready) return;
      const res = await this.axios.get(`/product`);
      res.data.reverse();
      this.data = res.data;
      this.pointsRange = [0, this.maxProductPoints];
    },
    async update(product, key) {
      await this.axios.put(`/product/${product._id}`, {
        [key]: product[key],
      });
    },
    async updateCategory(product, e) {
      if (e) {
        product.category = e.text ? e.value : e;
        this.$forceUpdate();
        await this.update(product, "category");
      }
    },
    async remove(product) {
      if (product.name) {
        if (!confirm(`確定刪除「${product.name}」?`)) return;
      }
      await this.axios.delete(`/product/${product._id}`);
      this.load();
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.empty-label {
  position: relative;

  ::v-deep .v-input__slot {
    justify-content: center;
  }
}
.typeWidth {
  width: 140px;
}

.range-inputs {
  display: flex;
  align-items: center;

  &__label {
    margin-right: 10px;
  }

  &__field {
    width: 140px;
  }

  &__dash {
    margin: 0 5px;
    width: 20px;
    height: 2px;
    background: black;
  }
}
</style>
